import ButtonGroup from 'components/button-group';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useServices } from 'services';
import { IgenerateCall } from 'interfaces';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './style.scss';
import { headersContentType, replaceMessages } from 'utils/help';
import { MESSAGES_ALERT } from 'utils/constants';
import { SettingContext, UserContext } from 'contexts';
import context from 'react-bootstrap/esm/AccordionContext';

const InvitationLink: React.FC = ({}: any) => {
    const [items] = useState([
        { text: 'Sms', placeholderText: '+46 | Enter Phone Number' },
        { text: 'E-mail', placeholderText: 'Enter Email Address' }
    ]);

    const [settingContext] = useContext(SettingContext);
    const [userContext] = useContext(UserContext);

    const callSetting = settingContext;
    const user = userContext;

    const defaultItem = items[0];
    const [sendType, setSendType] = useState(defaultItem.text);
    const [placeholderText, setPlaceholderText] = useState(defaultItem.placeholderText);
    const { api } = useServices();
    const [invitationLink, setInvitationLink] = useState('');
    const [generateCallInvitationLink, setGenerateCallInvitationLink] = useState();

    const notify = (isSent: boolean) => {
        if (isSent) {
            toast.success(replaceMessages(MESSAGES_ALERT.SENT_SUCCESS, `Invitation link`), {
                //Sent invitation link successful
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'light'
            });
        } else {
            toast.error(replaceMessages(MESSAGES_ALERT.SENT_FAILED, `Invitation link`), {
                position: 'top-right',
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: false,
                draggable: true,
                progress: undefined,
                theme: 'light'
            });
        }
    };
    const handleModalGenerateCall = async () => {
        const siteUrls = callSetting.siteURL;

        const userId: string = user.userId;
        const formDatanvitation: any = new FormData();
        const headers: any = headersContentType();
        formDatanvitation.append('salesAgentId', user.userId);
        let resutlGenerateCall: any = await api.dashboard.generateCallInvitation(formDatanvitation, {
            headers: headers
        });
        let getLinkInvitation = { link: null };
        const generateCall: IgenerateCall = resutlGenerateCall.data;

        if (generateCall.secretCode && siteUrls) {
            var string: any = siteUrls,
                substring = 'http';
            let getLink: any = `${siteUrls}?code=${generateCall.secretCode}&type=InvitationCall&salesAgentId=${userId}`;
            if (string.includes(substring) == false) {
                getLink = `https://${siteUrls}?code=${generateCall.secretCode}&type=InvitationCall&salesAgentId=${userId}`;
            }
            let newR = {
                link: getLink
            };
            getLinkInvitation = newR;
            return getLinkInvitation;
        }
    };

    const handleSubmitInvitation = async (event: any) => {
        event.preventDefault();
        let getLink = await handleModalGenerateCall();
        const headers: any = headersContentType();
        const formData = new FormData();

        if (invitationLink === '') {
            notify(false);
        } else {
            if (sendType === 'Sms') {
                let substrPhoneNumber: number = invitationLink.indexOf('+');
                let localNumber: string = invitationLink.substring(0, 3);
                let countryPhoneNumber = invitationLink.substring(3, invitationLink.length);
                let numberPhone: string = '';
                if (substrPhoneNumber > -1) {
                    numberPhone = localNumber + countryPhoneNumber;
                }
                let generateCallLink = getLink.link;

                formData.append('PhoneNumber', numberPhone);
                formData.append('InvitationLink', generateCallLink);

                try {
                    await api.dashboard.sendInvitationUsingSMS(formData, { headers: headers });
                    notify(true);
                } catch (e) {
                    notify(false);
                }
            } else if (sendType === 'E-mail') {
                let generateCallLink = getLink.link;

                formData.append('Email', invitationLink);
                formData.append('InvitationLink', generateCallLink);
                try {
                    await api.dashboard.sendInvitationUsingEmail(formData, { headers: headers });
                    notify(true);
                } catch (e) {
                    notify(false);
                }
            } else {
                notify(false);
            }
        }
    };

    const handleChange = (item: any) => {
        setSendType(item.text);
        setPlaceholderText(item.placeholderText);
    };

    return (
        <>
            <div className="col-lg-3  col-lg-3-5  col-lg-6-5  col-md-6 invitation-link">
                <div className="card">
                    <div className="card-body">
                        <div className="title">Send Invitation Link</div>
                        <form className="form" autoComplete="off" onSubmit={handleSubmitInvitation}>
                            <ButtonGroup items={items} onChange={handleChange} />
                            <div className="input-group">
                                <input
                                    name="send-information"
                                    className="input-send-invitation-link"
                                    type="text"
                                    placeholder={placeholderText}
                                    onChange={(e) => setInvitationLink(e.target.value)}
                                    value={invitationLink}
                                />
                                <button type="submit" className="input-send-invitation-info-button btn" disabled={!invitationLink} >
                                    Send
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default InvitationLink;
