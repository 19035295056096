import {
    ButtonTriggerToggleSlide,
    ButtonTriggerToggleSlidePreview,
    CardColorPicker,
    DatePickerOptionFully,
    TopContentHeader
} from 'components';
import { Footer, NavbarLeft, NavbarTop } from 'components/layout';
import { useContext, useEffect, useRef, useState } from 'react';
import './style.scss';
import { useNavigate, useParams } from 'react-router-dom';
import { TriggerPosition, baseColors } from './data';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import useTextEditor from 'hooks/useTextEditor';
import { BiCheck } from 'react-icons/bi';
import moment from 'moment';
import TimePicker from 'rc-time-picker';
import { useServices } from 'services';
import {  headersContentType, replaceMessages, toastProperty } from 'utils/help';
import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';
import { MESSAGES_ALERT } from 'utils/constants';
import { Itriggers } from 'interfaces';

interface Props {
    method?: string;
}

const TriggerDetail: React.FC<Props> = (props) => {
    const navigate = useNavigate();
    const { modules, formats } = useTextEditor();
    const { api } = useServices();
    const [pageUrl, setPageUrl] = useState<any>('');
    const [title, setTitle] = useState<any>('');
    const [message, setMessage] = useState<any>('');
    const [triggerTime, setTriggerTime] = useState<any>(moment('00:00', 'HH:mm'));
    const [imageProfile, setImageProfile] = useState<any>();
    const [active, setActive] = useState<any>(false);
    const { triggerId } = useParams();
    const [isCreatedTrigger, setIsCreatedTrigger] = useState<boolean>(true);
    const [textBtnSubmit, setTextBtnSubmit] = useState<string>('');
    const [startAt, setStartAt] = useState<any>(moment(new Date()).format());
    const [endAt, setEndAt] = useState<any>(moment(new Date()).format());
    const [dateActive, setDateActive] = useState<any>(moment(new Date()).format('YYYY-MM-DD'));
    const quillRef = useRef(null);
    const reactQuillRef = useRef(null);

    // destructuring rgba from state

    const [selectedPosition, setSelectedPosition] = useState<any>(TriggerPosition.LEFT);
    const [selectedColor, setSelectedColor] = useState<any>('#6259ce');


    const convertDateFormatToGeneralFormat = (date: string) => {
        let getDate = date.split('-');
        let year = getDate[2];
        let month = getDate[1];
        let day = getDate[0];
        return year + '-' + month + '-' + day;
    };

    const convertDateTimeToMinutes = (dateTimeFormat) => {
        let hours = dateTimeFormat.getHours();
        let mins = dateTimeFormat.getMinutes();
        let result = hours * 60 + mins;
        return result;
    };

    const handleSubmit = async (event: any) => {
        event.preventDefault();
        const startTime =
            convertDateFormatToGeneralFormat(event.target[10].value.toString()) +
            'T' +
            event.target[9].value.toString() +
            ':00';
        const endTime =
            convertDateFormatToGeneralFormat(event.target[12].value.toString()) +
            'T' +
            event.target[11].value.toString() +
            ':00';
        const createdTriggerDate = new Date(triggerTime._d);
        const triggerMinute: any = convertDateTimeToMinutes(createdTriggerDate);

        const formData = new FormData();
        formData.append('pageUrl', pageUrl);
        formData.append('title', title);
        formData.append('message', message);
        formData.append('startAt', startTime);
        formData.append('endAt', endTime);
        formData.append('triggerTime', triggerMinute);
        formData.append('color', selectedColor);
        formData.append('position', selectedPosition);
        formData.append('active', active);
        formData.append('imageUrl', imageProfile);

        console.log('formData', formData);
        

        if (triggerId) {
            await api.trigger.updateTrigger(triggerId, formData, headersContentType());
            toast.success(
                replaceMessages(MESSAGES_ALERT.UPDATE_SUCCESS, `trigger`),
                toastProperty('top-right', 2000, false, true, false, true, undefined, 'light')
            );
            setTimeout(() => {
                navigate('/triggers');
            }, 2500);
        } else {
           api.trigger.saveTrigger(formData, headersContentType()).then((result)=>{
            if(result.errors.length === 0) {
                toast.success(
                    replaceMessages(MESSAGES_ALERT.SAVE_SUCCESS, `New trigger`),
                    toastProperty('top-right', 2000, false, true, false, true, undefined, 'light')
                );
                setTimeout(() => {
                    navigate('/triggers');
                }, 2500);
            }else{
                result.errors.forEach((item:any)=>{
                    toast.error(
                        replaceMessages(MESSAGES_ALERT.SAVE_FAILED, `new trigger owing to ${item.errorMessage }`),
                        toastProperty('top-right', 4000, false, true, false, true, undefined, 'light')
                    );
                })
              
            }
            }).catch((error)=>{
                toast.error(
                    replaceMessages(MESSAGES_ALERT.SAVE_FAILED, `new trigger`),
                    toastProperty('top-right', 2000, false, true, false, true, undefined, 'light')
                );
            });
           
        }
    };

    const handleChange = (html) => {
        var limit = 200;
        var quill = quillRef.current;
        quill.on('text-change', function () {
            if (quill.getLength() > limit) {
                quill.deleteText(limit, quill.getLength());
            }
        });
        setMessage(html);
    };

    useEffect(() => {
        if (typeof reactQuillRef.current?.getEditor !== 'function') return;
        quillRef.current = reactQuillRef.current?.getEditor();
    }, []);

    function toHoursAndMinutes(totalMinutes) {
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
        return `${padToTwoDigits(hours)}:${padToTwoDigits(minutes)}`;
    }
    function padToTwoDigits(num) {
        return num.toString().padStart(2, '0');
    }

    useEffect(() => {
        if (triggerId) {
            setIsCreatedTrigger(false);
            setTextBtnSubmit('Update');
            (async () => {
                const result = await api.trigger.getTriggerDetail(triggerId);
                const resultData: Itriggers = result.data;
                setPageUrl(resultData.pageUrl);
                setTitle(resultData.title);
                setMessage(resultData.message);
                setStartAt(resultData.startAt);
                setEndAt(resultData.endAt);
                setTriggerTime(moment(toHoursAndMinutes(resultData.triggerTime), 'HH:mm'));
                setSelectedColor(resultData.color);
                setImageProfile(resultData.imageUrl);
                setSelectedPosition(resultData.position);
            })();
        } else {
            setIsCreatedTrigger(true);
            setTextBtnSubmit('Save');
        }

    }, [triggerId]);

    return (
        <>
            <NavbarTop />
            <NavbarLeft />
            <ToastContainer />
            <div className="content-page trigger-detail-app">
                <div className="container-fluid">
                    <TopContentHeader
                        titleOfPage={`TRIGGERS`}
                        hasButton={true}
                        buttonName={textBtnSubmit}
                        hasSymbolButton={true}
                        symbolButton={<BiCheck color={'white'} size={22} />}
                        functionButton={(e) => {}}
                        isButtonSubmitType={true}
                    />
                    {isCreatedTrigger ? (
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Create Trigger</h3>
                                    </div>
                                    <div className="card-body">
                                        <form
                                            id="myform"
                                            action=""
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault(), handleSubmit(e);
                                            }}
                                            noValidate>
                                            <div className="form-group">
                                                <label>Page Url:</label>
                                                <input
                                                    type="text"
                                                    name=""
                                                    className="form-control"
                                                    onChange={(e) => setPageUrl(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group">
                                            <label>Title:</label>
                                                <input
                                                    type="text"
                                                    name=""
                                                    className="form-control"
                                                    onChange={(e) => setTitle(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Message:</label>
                                                <ReactQuill
                                                    ref={reactQuillRef}
                                                    value={message}
                                                    modules={modules}
                                                    formats={formats}
                                                    onChange={handleChange}
                                                />
                                            </div>
                                            <div className="form-group">
                                            
                                                <div className="contianer-trigger-date-option">
                                                    <DatePickerOptionFully
                                                        isUpdate={true}
                                                        startTime={startAt}
                                                        endTime={endAt}
                                                        dateActive={dateActive}
                                                        startLabel={"Start :"}
                                                        endLabel={"End :"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Trigger Time:</label>
                                                <TimePicker
                                                    className="trigger-time"
                                                    showSecond={false}
                                                    value={triggerTime}
                                                    defaultValue={triggerTime}
                                                    onChange={(e) => setTriggerTime(e)}
                                                    inputReadOnly={false}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>Background:</label>
                                                <CardColorPicker onChange={setSelectedColor} />
                                            </div>

                                            <div className="trigger-designer">
                                                <div className="trigger-preview-design">
                                                    <ButtonTriggerToggleSlidePreview
                                                        message={message}
                                                        topPosition={0}
                                                        leftPosition={0}
                                                        rightPosition={0}
                                                        bottomPosition={0}
                                                        isPreview={false}
                                                        resultUriImage={(e:any) => setImageProfile(e)}
                                                        backgroundColor={selectedColor}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card card-position">
                                    <div className="card-header">
                                        <h3>Position</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div
                                                    onClick={() => setSelectedPosition(TriggerPosition.LEFT)}
                                                    className={`position-select ${
                                                        selectedPosition == TriggerPosition.LEFT ? 'active' : ''
                                                    }`}>
                                                    <div className="position-wrapper">
                                                        <div className="preview-item-xs">
                                                            <ButtonTriggerToggleSlide position={`left`} />
                                                        </div>
                                                    </div>
                                                    <label>Left</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    onClick={() => setSelectedPosition(TriggerPosition.RIGHT)}
                                                    className={`position-select ${
                                                        selectedPosition == TriggerPosition.RIGHT ? 'active' : ''
                                                    }`}>
                                                    <div className="position-wrapper">
                                                        <div className="preview-item-xs">
                                                            <ButtonTriggerToggleSlide position={`right`} />
                                                        </div>
                                                    </div>
                                                    <label>Right</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div
                                                    onClick={() => setSelectedPosition(TriggerPosition.LEFT_UP)}
                                                    className={`position-select ${
                                                        selectedPosition == TriggerPosition.LEFT_UP ? 'active' : ''
                                                    }`}>
                                                    <div className="position-wrapper">
                                                        <div className="preview-item-xs">
                                                            <ButtonTriggerToggleSlide position={`left-up`} />
                                                        </div>
                                                    </div>
                                                    <label>Left Up</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    onClick={() => setSelectedPosition(TriggerPosition.RIGHT_UP)}
                                                    className={`position-select ${
                                                        selectedPosition == TriggerPosition.RIGHT_UP ? 'active' : ''
                                                    }`}>
                                                    <div className="position-wrapper">
                                                        <div className="preview-item-xs">
                                                            <ButtonTriggerToggleSlide position={`right-up`} />
                                                        </div>
                                                    </div>
                                                    <label>Right Up</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="row">
                            <div className="col-md-6">
                                <div className="card">
                                    <div className="card-header">
                                        <h3>Update Trigger</h3>
                                    </div>
                                    <div className="card-body">
                                        <form
                                            id="myform"
                                            action=""
                                            className="form-horizontal"
                                            onSubmit={(e) => {
                                                e.preventDefault(), handleSubmit(e);
                                            }}
                                            noValidate>
                                            <div className="form-group">
                                                <label>Page Url:</label>
                                                <input
                                                    type="text"
                                                    name=""
                                                    className="form-control"
                                                    defaultValue={pageUrl}
                                                    onChange={(e) => setPageUrl(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Title:</label>
                                                <input
                                                    type="text"
                                                    name=""
                                                    className="form-control"
                                                    defaultValue={title}
                                                    onChange={(e) => setTitle(e.target.value)}
                                                />
                                            </div>
                                            <div className="form-group">
                                                <label>Message:</label>
                                                <ReactQuill
                                                    value={message}
                                                    defaultValue={message}
                                                    modules={modules}
                                                    formats={formats}
                                                    onChange={setMessage}
                                                />
                                            </div>
                                            <div className="form-group">
                                           
                                                <div className="contianer-trigger-date-option">
                                                    <DatePickerOptionFully
                                                        isUpdate={true}
                                                        startTime={startAt}
                                                        endTime={endAt}
                                                        dateActive={dateActive}
                                                        startLabel={"Start :"}
                                                        endLabel={"End :"}
                                                    />
                                                </div>
                                            </div>
                                            <div className="form-group">
                                                <label>Start Within:</label>
                                                <TimePicker
                                                    className="trigger-time"
                                                    showSecond={false}
                                                    value={triggerTime}
                                                    defaultValue={triggerTime}
                                                    onChange={(e) => setTriggerTime(e)}
                                                    inputReadOnly={false}
                                                />
                                            </div>

                                            <div className="form-group">
                                                <label>CardColorPicker :</label>
                                                <CardColorPicker
                                                    title="Secondary Color for Buttons"
                                                    subtitle="Select the color for the secondary buttons"
                                                />
                                            </div>

                                            <div className="trigger-designer">
                                                <div className="trigger-preview-design">
                                                    <ButtonTriggerToggleSlidePreview
                                                        message={message}
                                                        topPosition={50}
                                                        leftPosition={250}
                                                        rightPosition={0}
                                                        bottomPosition={0}
                                                        isPreview={false}
                                                        resultUriImage={(e) => setImageProfile(e)}
                                                        imageUrl={imageProfile}
                                                        backgroundColor={selectedColor}
                                                    />
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="card card-position">
                                    <div className="card-header">
                                        <h3>Position</h3>
                                    </div>
                                    <div className="card-body">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div
                                                    onClick={() => setSelectedPosition(TriggerPosition.LEFT)}
                                                    className={`position-select ${
                                                        selectedPosition == TriggerPosition.LEFT ? 'active' : ''
                                                    }`}>
                                                    <div className="position-wrapper">
                                                        <div className="preview-item-xs">
                                                            <ButtonTriggerToggleSlide position={`left`} />
                                                        </div>
                                                    </div>
                                                    <label>Left</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    onClick={() => setSelectedPosition(TriggerPosition.RIGHT)}
                                                    className={`position-select ${
                                                        selectedPosition == TriggerPosition.RIGHT ? 'active' : ''
                                                    }`}>
                                                    <div className="position-wrapper">
                                                        <div className="preview-item-xs">
                                                            <ButtonTriggerToggleSlide position={`right`} />
                                                        </div>
                                                    </div>
                                                    <label>Right</label>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-6">
                                                <div
                                                    onClick={() => setSelectedPosition(TriggerPosition.LEFT_UP)}
                                                    className={`position-select ${
                                                        selectedPosition == TriggerPosition.LEFT_UP ? 'active' : ''
                                                    }`}>
                                                    <div className="position-wrapper">
                                                        <div className="preview-item-xs">
                                                            <ButtonTriggerToggleSlide position={`left-up`} />
                                                        </div>
                                                    </div>
                                                    <label>Left Up</label>
                                                </div>
                                            </div>
                                            <div className="col-md-6">
                                                <div
                                                    onClick={() => setSelectedPosition(TriggerPosition.RIGHT_UP)}
                                                    className={`position-select ${
                                                        selectedPosition == TriggerPosition.RIGHT_UP ? 'active' : ''
                                                    }`}>
                                                    <div className="position-wrapper">
                                                        <div className="preview-item-xs">
                                                            <ButtonTriggerToggleSlide position={`right-up`} />
                                                        </div>
                                                    </div>
                                                    <label>Right Up</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            {/* <div className={`color-picker ${showColorPicker ? 'show' : ''}`}>
                <div className="toolbar">
                    <button type="button" className="btn btn-icon" onClick={() => setShowColorPicker(false)}>
                        <AiOutlineCloseCircle size={20} />
                    </button>
                </div>
                <SketchPicker
                    onChange={(color) => {
                        setSketchPickerColor(color.rgb);
                        setSelectedColor(`rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`);
                    }}
                    color={sketchPickerColor}
                />
            </div> */}
            <Footer />
        </>
    );
};
export default TriggerDetail;
